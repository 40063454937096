import React, { useEffect, useRef, useState } from 'react';
import {
  Affix,
  Alert,
  Button,
  Card,
  Empty,
  Grid,
  Image,
  Message,
  Modal,
  Notification,
  Popconfirm,
  Space,
  Spin,
  Steps,
} from '@arco-design/web-react';
import { useSelector, useDispatch } from 'react-redux';
import './style/invite.less';

import IconStep1 from '@/assets/icon/shouji.png';
import IconStep2 from '@/assets/icon/step-2.png';
import IconStep3 from '@/assets/icon/step-3.png';
import IconStep4 from '@/assets/icon/step-4.png';
import IconStep5 from '@/assets/icon/step-5.png';
import { expertListById, inviteCreate } from '@/request/api';
import ExpertUserInfoMiniCard from '@/pages/index/components/ExpertUserInfoMini';
import InviteFormCard from './InviteFormCard';
import InviteContactFormCard from './InviteContactFormCard';
const { Row, Col } = Grid;

const InviteFormModal = () => {
  const inviteFormRef = useRef(null);
  const inviteContactFormRef = useRef(null);
  const inviteFormVisible = useSelector((state) => state.inviteFormVisible);
  const dispatch = useDispatch();
  const inviteExpertIds = useSelector((state) => state.inviteExpertIds);
  const [expertLoading, setExpertLoading] = useState(false);
  const [expertList, setExpertList] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);

  const userInfo = useSelector((state) => state.userInfo);
  const token = useSelector((state) => state.token);
  const [isLogined, setIsLogined] = useState(false);

  useEffect(() => {
    if ((userInfo?.id || 0) > 0 && token) {
      setIsLogined(true);
    } else {
      setIsLogined(false);
    }
  }, [token, userInfo]);

  const loadExpertList = async () => {
    if (expertLoading) return;
    setExpertLoading(true);
    try {
      const response = await expertListById({
        ids: inviteExpertIds,
      });
      if (response.code == 200) {
        setExpertList(response.data.list || []);
      }
    } catch (e) {}
    setExpertLoading(false);
  };

  const handleRemove = (expert: any) => {
    dispatch({
      type: 'update-inviteIds',
      payload: {
        inviteExpertIds: inviteExpertIds.filter(
          (id: number) => id !== expert.id
        ),
      },
    });
    // 从list中移除
    setExpertList(expertList.filter((item) => item.id !== expert.id));
    Notification.warning({
      title: '移除成功',
      content: `专家【${expert.name}】已从待邀请库中移除`,
    });
  };

  const handleSubmit = async () => {
    if (submitLoading) return;
    const submitForm = {
      expertIds: inviteExpertIds,
      name: '',
      mobile: '',
      company: '',
      inviteFields: [],
    };
    if (submitForm.expertIds.length === 0) {
      Message.error('请至少邀请一个专家');
      return;
    }

    if (inviteFormRef.current) {
      try {
        const values = await inviteFormRef.current.getValues();
        console.warn('values ===>', values);
        Object.entries(values).forEach(([key, value]) => {
          console.warn('key ==>', key);
          console.warn('value ==>', value);
          submitForm.inviteFields.push({
            id: +key.replace('field-', ''),
            value: typeof value === 'undefined' ? '' : value,
          });
        });
      } catch (e) {
        Message.error('请填写完整的邀请信息');
        return;
      }
      // 格式化
    }
    if (inviteContactFormRef.current) {
      try {
        const values = await inviteContactFormRef.current.getValues();
        submitForm.name = values.name;
        submitForm.mobile = values.mobile;
        submitForm.company = values.company;
      } catch (e) {
        Message.error('请填写完整的联系方式');
        return;
      }
    }

    // 开始提交
    setSubmitLoading(true);
    console.warn('submitForm ===>', submitForm);
    try {
      const response = await inviteCreate(submitForm);
      if (response.code === 200) {
        Notification.success({
          title: '提交成功',
          content: '邀请信息已成功提交',
        });
        dispatch({
          type: 'update-inviteIds',
          payload: { inviteExpertIds: [] },
        });
        dispatch({
          type: 'update-invite-visible',
          payload: { inviteFormVisible: false },
        });
      } else {
        Notification.error({
          title: '提交失败',
          content: response.message,
        });
      }
    } catch (e) {
      Notification.error({
        title: '提交失败',
        content: '请稍后重试',
      });
    }
    setSubmitLoading(false);
  };

  useEffect(() => {
    if (inviteFormVisible) {
      loadExpertList();
    }
  }, [inviteFormVisible]);

  return (
    <Modal
      title={'邀请专家'}
      visible={inviteFormVisible}
      footer={
        isLogined ? (
          <>
            <Button
              onClick={() => {
                dispatch({
                  type: 'update-invite-visible',
                  payload: { inviteFormVisible: false },
                });
              }}
            >
              取消
            </Button>
            <Button type="primary" onClick={handleSubmit}>
              提交信息
            </Button>
          </>
        ) : null
      }
      style={{ width: 800, minHeight: 600 }}
      unmountOnExit
      maskClosable={false}
      focusLock={false}
      escToExit={false}
      onCancel={() => {
        dispatch({
          type: 'update-invite-visible',
          payload: { inviteFormVisible: false },
        });
      }}
    >
      {!isLogined && (
        <Alert
          type="warning"
          content={
            <Space>
              <div>您还没有登录，邀请信息不能提交</div>
              <Button
                type="text"
                onClick={() => {
                  dispatch({
                    type: 'update-login-visible',
                    payload: { loginVisible: true },
                  });
                }}
              >
                去登录
              </Button>
            </Space>
          }
          showIcon
          closable
        />
      )}
      <div className="invite-form-dialog">
        <Card
          title="服务流程"
          style={{
            boxShadow: `0 0 10px 0 rgba(0, 0, 0, 0.08)`,
            marginBottom: 20,
          }}
        >
          <Steps
            current={0}
            style={{ marginBottom: 10 }}
            labelPlacement="vertical"
          >
            <Steps.Step
              title={
                <div style={{ fontWeight: 'bold', fontSize: 14, marginTop: 5 }}>
                  用户邀请专家
                </div>
              }
              icon={
                <Image
                  style={{
                    background: 'var(--color-bg-2)',
                  }}
                  src={IconStep1}
                  width={30}
                  preview={false}
                />
              }
            />
            <Steps.Step
              title={
                <div style={{ fontWeight: 'bold', fontSize: 14, marginTop: 5 }}>
                  平台协调专家
                </div>
              }
              icon={
                <Image
                  style={{
                    background: 'var(--color-bg-2)',
                  }}
                  src={IconStep3}
                  width={30}
                  preview={false}
                />
              }
            />
            <Steps.Step
              title={
                <div style={{ fontWeight: 'bold', fontSize: 14, marginTop: 5 }}>
                  专家受邀参会
                </div>
              }
              icon={
                <Image
                  style={{
                    background: 'var(--color-bg-2)',
                  }}
                  src={IconStep4}
                  width={30}
                  preview={false}
                />
              }
            />
            <Steps.Step
              title={
                <div style={{ fontWeight: 'bold', fontSize: 14, marginTop: 5 }}>
                  用户评价专家
                </div>
              }
              icon={
                <Image
                  style={{
                    background: 'var(--color-bg-2)',
                  }}
                  src={IconStep5}
                  width={30}
                  preview={false}
                />
              }
            />
          </Steps>
        </Card>

        <Spin
          loading={expertLoading}
          style={{ width: '100%' }}
          tip="请稍候，正在加载专家信息"
        >
          <Card
            title="想要邀请的专家"
            style={{
              boxShadow: `0 0 10px 0 rgba(0, 0, 0, 0.08)`,
              marginBottom: 20,
              width: '100%',
            }}
          >
            <div
              style={{
                minHeight: 100,
                width: '100%',
              }}
            >
              {expertList.length > 0 ? (
                <Row gutter={20}>
                  {expertList.map((expert) => {
                    return (
                      <Col
                        span={8}
                        xs={24}
                        sm={24}
                        md={12}
                        lg={8}
                        xl={8}
                        xxl={6}
                        key={expert.id}
                        style={{ marginBottom: 20 }}
                      >
                        <div
                          style={{
                            position: 'absolute',
                            right: 30,
                            bottom: 10,
                          }}
                        >
                          <Popconfirm
                            content="确认要移除这个专家吗？"
                            onOk={() => {
                              handleRemove(expert);
                            }}
                          >
                            <Button type="outline" status="danger" size="small">
                              移除
                            </Button>
                          </Popconfirm>
                        </div>
                        <ExpertUserInfoMiniCard single expert={expert} />
                      </Col>
                    );
                  })}
                </Row>
              ) : (
                <Empty description="快去邀请专家吧" />
              )}
            </div>
          </Card>
        </Spin>

        <Card
          title="您将呈现给专家的项目信息"
          style={{
            boxShadow: `0 0 10px 0 rgba(0, 0, 0, 0.08)`,
            marginBottom: 20,
            width: '100%',
          }}
        >
          <InviteFormCard ref={inviteFormRef} />
        </Card>

        <Card
          title="您的联系方式"
          style={{
            boxShadow: `0 0 10px 0 rgba(0, 0, 0, 0.08)`,
            marginBottom: 20,
            width: '100%',
          }}
        >
          <InviteContactFormCard ref={inviteContactFormRef} />
        </Card>
      </div>
    </Modal>
  );
};

export default InviteFormModal;
